.flow-center-container {
  background-color: #fff;
  margin: 0 20px;
  position: relative;
}
.flow-center-container .el-tabs__item {
    padding: 0px 10px;
}
.flow-center-container .work-folw-wrap .content {
    line-height: 2.3;
    padding: 0px 10px;
}
.flow-center-container .work-folw-wrap .content .loadMore {
      height: 30px;
      line-height: 30px;
      text-align: center;
}
.flow-center-container .work-folw-wrap .content .content-item {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between;
      height: 27px;
      line-height: 27px;
}
.flow-center-container .work-folw-wrap .content .content-item .item-title {
        width: calc(100% - 200px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
        position: relative;
}
.flow-center-container .work-folw-wrap .content .content-item .item-title .item-icon--unread {
          height: 32px;
          position: absolute;
          bottom: 4px;
}
.flow-center-container .work-folw-wrap .content .content-item .item-title:hover {
        color: #0270c1;
}
.flow-center-container .work-folw-wrap .content .content-item .item--unread {
        font-weight: bold;
}
.flow-center-container .work-folw-wrap .content .content-item .item-rutask {
        width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        cursor: pointer;
}
.flow-center-container .work-folw-wrap .content .content-item .item-time {
        width: 78px;
}
.flow-center-container .work-folw-wrap .content .content-item .item-publisher {
        width: 72px;
        text-align: right;
}
.flow-center-container .work-folw-wrap .content .item-divider {
      margin: 0;
}
.flow-center-container .more {
    font-size: var(--rootFontSize);
    position: absolute;
    top: 8px;
    right: 20px;
    cursor: pointer;
    font-weight: 600;
    color: #7f7f7f;
}
